<template>
  <div>
    <v-container>
      <v-data-table
        :loading="loadingTable"
        :headers="headers"
        :items="progress"
        :items-per-page="5"
        class="elevation-1"
        :pageCount="numberOfPagesPaginate"
        :page="currentPagePaginate"
        :server-items-length="totalStagesPaginate"
        :options.sync="options"
        :footer-props="{
          itemsPerPageText: 'Itens por pagina',
          itemsPerPageOptions: [5, 10, 15],
          showFirstLastPage: true,
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Avanço Físico</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loadingBtnNewPeriod"
              :disabled="loadingBtnNewPeriod"
              rounded
              min-width="150"
              @click="newPhysicalProgress()"
            >
              <v-icon>mdi-plus</v-icon>
              Novo Avanço
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.cost.name }}
        </template>
        <template v-slot:item.period="{ item }">
          {{ formatDate(item.date_start) + " - " + formatDate(item.date_end) }}
        </template>

        <template v-slot:item.realized="{ item }">
          {{ formatMoney(item.realized_percentage || 0) + " %" }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="item.last == 'last'"
                v-bind="attrs"
                v-on="on"
                icon
                color="success"
                class="mr-2"
                @click="newPhysicalProgress(item)"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span>Adicionar Novo Período </span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="item.lock == 'not'"
                v-if="getUser.user_type == 'super_admin' || item.last == 'last'"
                v-bind="attrs"
                v-on="on"
                icon
                color="primary"
                class="mr-2"
                @click="editItem(item)"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="secondary"
                class="mr-2"
                @click="viewItem(item)"
              >
                <v-icon> mdi-magnify </v-icon>
              </v-btn>
            </template>
            <span>Visualizar</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.lock == 'yes'"
                v-bind="attrs"
                v-on="on"
                icon
                color="secondary"
                class="mr-2"
                @click="unlockShow(item)"
              >
                <v-icon> mdi-lock-open-variant-outline </v-icon>
              </v-btn>
              <v-btn
                v-else
                v-bind="attrs"
                v-on="on"
                icon
                color="secondary"
                class="mr-2"
                @click="unlockShow(item)"
              >
                <v-icon> mdi-lock-outline </v-icon>
              </v-btn>
            </template>
            <span>Bloqueio</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data> Nenhum registro encontrado </template>
      </v-data-table>
    </v-container>

    <!-- modal desbloquear avaço fisico -->
    <v-dialog v-model="dialogUnlock" max-width="500">
      <v-card>
        <BarTitle title="Autenticação" @close="dialogUnlock = false"></BarTitle>
        <v-card-text>
          <v-row v-if="lockPhysicalProgress">
            <v-col>
              <h2 v-if="lockPhysicalProgress.lock == 'not'" class="text-center">
                Desbloquear
              </h2>
              <h2 v-else class="text-center">Bloquear</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="lockPassWord" label="Senha" outlined></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn x-large text @click="close()">FECHAR</v-btn>
              <v-btn
                :loading="loadingBtnConfirmLock"
                :disabled="loadingBtnConfirmLock"
                x-large
                color="primary"
                @click="sendLockConfirm()"
                >Confirmar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CONFIRM NOVO PERIODO -->
    <v-dialog width="500" v-model="dialogConfirmNewPeriod">
        <v-card>
            <BarTitle title="NOVO AVANÇO" @close="dialogConfirmNewPeriod=false"></BarTitle>
            <v-card-text class="text-center">
                <h2>Deseja realmente criar um novo avanço ?</h2>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large color="error" @click="dialogConfirmNewPeriod=false">NÂO</v-btn>
                <v-btn  large color="primary" @click="confirmNewPhysicalProgress()">SIM</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- MSG DELETE -->
    <MsgDelete
      @confirm="destroyItem()"
      :loading="loadingDelete"
      message="Deseja excluir ?"
      @close="dialogDelete = false"
      v-model="dialogDelete"
    ></MsgDelete>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MsgDelete from "@/pages/Components/MsgDelete.vue";
import BarTitle from "@/pages/Components/BarTitle.vue";

export default {
  components: {
    MsgDelete,
    BarTitle,
  },
  data: () => ({
    dialogConfirmNewPeriod:false,
    lockPassWord: "",
    lockPhysicalProgress: null,
    loadingBtnConfirmLock: false,
    dialogUnlock: false,
    loadingBtnNewPeriod: false,
    dialogDelete: false,
    loadingTable: false,
    totalStagesPaginate: null,
    numberOfPagesPaginate: null,
    currentPagePaginate: null,
    deleteItem: null,
    loadingDelete: false,
    options: {},
    headers: [
      {
        text: "DESCRIÇÃO",
        align: "start",
        sortable: false,
        value: "description",
      },
      { text: "PERÍODO", value: "period" },
      { text: "REALIZADO (%)", value: "realized" },
      { text: "", align: "end", value: "actions" },
    ],
    progress: [],
  }),
  created() {
    this.initialize();
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
    },
  },
  computed: {
    ...mapGetters("Login", ["getUser"]),
  },
  methods: {
    ...mapActions("PhysicalProgress", ["index", "destroy", "newPeriod", "lockConfirm"]),

    initialize() {
      this.loadingTable = true;

      const data = {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        search: "",
      };

      this.index(data)
        .then((response) => {
          this.progress = response.data.data;
          this.totalStagesPaginate = response.data.total;
          this.numberOfPagesPaginate = response.data.last_page;
          this.currentPagePaginate = response.data.current_page;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    /**
     * Abri dialog de desbloqueio
     */
    unlockShow(PhysicalProgress) {
      this.lockPassWord = "";
      this.lockPhysicalProgress = PhysicalProgress;
      this.dialogUnlock = true;
    },
    /**
     * confirma o desbloqueio
     */
    sendLockConfirm() {
      let lock = "not";
      if (this.lockPhysicalProgress.lock == "yes") {
        lock = "not";
      } else {
        lock = "yes";
      }
      this.loadingBtnConfirmLock = true;
      const data = {
        id: this.lockPhysicalProgress.id,
        lock: lock,
        password: this.lockPassWord,
      };

      this.lockConfirm(data)
        .then((response) => {
          let index = this.progress.indexOf(this.lockPhysicalProgress);
          Object.assign(this.progress[index], response.data);

          if (this.progress[index].lock == "yes") {
            this.$toast.success("Desbloqueado com sucesso.");
          } else {
            this.$toast.success("bloqueado com sucesso.");
          }

          this.dialogUnlock = false;
        })
        .catch((error) => {
          if (error.response.status == 423) {
            this.$toast.error(error.response.data);
          } else {
            this.$toast.error("Erro ao executar a ação.");
          }
        })
        .finally(() => {
          this.loadingBtnConfirmLock = false;
        });
    },
    editItem(PhysicalProgress) {
      this.$router.push(
        "/system/physical_progress/edit_physical_progress/" + PhysicalProgress.id
      );
    },
    viewItem(PhysicalProgress) {
      this.$router.push(
        "/system/physical_progress/edit_physical_progress/" +
          PhysicalProgress.id +
          "/view"
      );
    },
    /*
        CRIAR NOVO PERIODO
    */
    newPhysicalProgress(item) {
        this.itemNewPhysicalProgress = item
        this.dialogConfirmNewPeriod = true

    },

    confirmNewPhysicalProgress() {
        this.dialogConfirmNewPeriod = false
        this.loadingBtnNewPeriod = true;

      let data = {
        physical_progress:  this.itemNewPhysicalProgress,
      };
      this.newPeriod(data)
        .then((response) => {
          const PhysicalProgress = response.data;

          if (PhysicalProgress.id) {
            this.$router.push(
              "/system/physical_progress/edit_physical_progress/" + PhysicalProgress.id
            );
            this.$toast.success("Novo período criado.");
          } else {
            this.$router.push("/system/physical_progress/edit_physical_progress");
          }
        })
        .catch((error) => {
          if (error.response.status == 423) {
            this.$swal({
              confirmButtonColor: "#3085d6",
              position: "center",
              text: error.response.data,
              icon: "info",
              title: "",
            });
          }
        })
        .finally(() => {
          this.loadingBtnNewPeriod = false;
        });
    },

    confirmDeleteItem(physicalProgress) {
      this.deleteItem = physicalProgress;
      this.dialogDelete = true;
    },
    destroyItem() {
      this.loadingDelete = true;
      this.destroy(this.deleteItem)
        .then(() => {
          //fecha o  dialog
          this.dialogDelete = false;

          //excluir da lista
          let editedIndex = this.progress.indexOf(this.deleteItem);
          this.progress.splice(editedIndex, 1);

          //msg de sucess
          this.$toast.success("Deletado com sucesso.");
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    formatMoney(number) {
      number = this.parseNumber(number);
      return number.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    parseNumber(number) {
      if (isNaN(number)) {
        return 0;
      }
      let result = parseFloat(number);
      return result;
    },
    formatDate(date) {
      let date_split = date.split("-");
      return date_split[2] + "/" + date_split[1] + "/" + date_split[0];
    },
  },
};
</script>
